<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data() {
    return {
      title: "聊天室管理",
      items: [
        {
          text: "留言/聊天室管理",
          href: "/msgmanagement",
        },
        {
          text: "聊天室管理",
          active: true,
        }
      ],

      room_list:[
        {
          img:require('@/assets/images/maxresdefault_live.jpg'),
          name: '【週大爆卦 下】陳時中"木偶"新人設小英超愛?慘!圖解冷宮中的小智還沒復寵!'
        },
        {
          img:require('@/assets/images/maxresdefault_live.jpg'),
          name: '【週大爆卦 下】陳時中"木偶"新人設小英超愛?慘!圖解冷宮中的小智還沒復寵!'
        },
        {
          img:require('@/assets/images/maxresdefault_live.jpg'),
          name: '【週大爆卦 下】陳時中"木偶"新人設小英超愛?慘!圖解冷宮中的小智還沒復寵!'
        },
        {
          img:require('@/assets/images/maxresdefault_live.jpg'),
          name: '【週大爆卦 下】陳時中"木偶"新人設小英超愛?慘!圖解冷宮中的小智還沒復寵!'
        }
      ],
      chatData:[
        {
          id: 1,
          image: require("@/assets/images/users/avatar-2.jpg"),
          name: "Brandon Smith",
          message: "How are you today?",
          time: "4:30am",
          status: "away"
        },
        {
          id: 2,
          image: require("@/assets/images/users/avatar-5.jpg"),
          name: "James Zavel",
          message: "Hey! a reminder for tomorrow's meeting...",
          time: "5:30am",
          status: "online"
        },
        {
          id: 3,
          image: require("@/assets/images/users/avatar-7.jpg"),
          name: "Maria C",
          message: "Are we going to have this week's planning meeting today?",
          time: "Thu",
          status: "away"
        },
        {
          id: 4,
          image: require("@/assets/images/users/avatar-8.jpg"),
          name: "Rhonda D",
          message: "Please check these design assets...",
          time: "Wed",
          status: "warning"
        },
        {
          id: 5,
          image: require("@/assets/images/users/avatar-3.jpg"),
          name: "Michael H",
          message: "Are you free for 15 min? I would like to discuss something...",
          time: "Tue",
          status: "do-not-disturb"
        },
        {
          id: 6,
          image: require("@/assets/images/users/avatar-6.jpg"),
          name: "Thomas R",
          message: "Let's have meeting today between me, you and Tony...",
          time: "Tue",
          status: "away"
        },
        {
          id: 7,
          image: require("@/assets/images/users/avatar-8.jpg"),
          name: "Thomas J",
          message: "Howdy?",
          time: "Tue",
          status: "online"
        },
        {
          id: 8,
          image: require("@/assets/images/users/avatar-4.jpg"),
          name: "Ricky J",
          message: "Are you interested in learning?",
          time: "Tue",
          status: "online"
        },
        {
          id: 8,
          image: require("@/assets/images/users/avatar-4.jpg"),
          name: "Ricky J",
          message: "Are you interested in learning?",
          time: "Tue",
          status: "online"
        },
        {
          id: 8,
          image: require("@/assets/images/users/avatar-4.jpg"),
          name: "Ricky J",
          message: "Are you interested in learning?",
          time: "Tue",
          status: "online"
        },
        {
          id: 8,
          image: require("@/assets/images/users/avatar-4.jpg"),
          name: "Ricky J",
          message: "Are you interested in learning?",
          time: "Tue",
          status: "online"
        },
        {
          id: 8,
          image: require("@/assets/images/users/avatar-4.jpg"),
          name: "Ricky J",
          message: "Are you interested in learning?",
          time: "Tue",
          status: "online"
        }
      ],

      tab:1,

      now_room: false,
    };
  },
  computed:{
    
  },
  mounted() {
    
  },
  methods: {
    
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  methods:{
    clickTab(_tab){
      this.tab = _tab
    },
    selectRoom(_item){
      this.now_room = _item
    },
    setBottom(){
      var div = document.getElementById('room-content');
      div.scrollTop = div.scrollHeight; 
    }
  }
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-3.pl-0
      b-card-body.pt-0.pr-0
        b-card.group-btn.mb-3
          b-button.width-md.mr-2(:variant=" tab !== 1 ? 'outline-primary' : 'primary'" @click="clickTab(1)") 正在直播
          b-button.width-md(:variant=" tab !== 2 ? 'outline-primary' : 'primary'" @click="clickTab(2)") 已結束直播
        .scroll-body
          .group-card.mb-2(v-for="(item, num) in room_list", :key="num" @click="selectRoom(item)")
            .img-box
              img(:src="item.img", alt="alt")
              p.text.mb-0.ml-2 {{ item.name }}
    .col-lg-9
      .card.card-box
        .room-main(v-if="now_room")
          .room-head
            img(:src="now_room.img", alt="alt")
            p.text.mb-0.ml-2 {{ now_room.name }}
          .room-content#room-content.row
            .col-11.text-center.mt-2
              b-button.load.text-center(variant='primary') 查看較早訊息
            .col-11
              a.text-body(href='javascript:void(0);' v-for='(item, index) in chatData' :key='index' @click='chatUsername(item.name, item.image)')
                .media.p-2
                  .position-relative
                    img.mr-2.rounded-circle(:src='item.image' height='48' alt='user')
                  .media-body
                    h5.mt-0.mb-0.font-14
                      span.font-weight-bold.font-14 {{ item.name }}
                      span.ml-2.font-12.text-muted {{ item.time }}
                    p.mt-1.mb-0.font-14
                      span.w-75 {{ item.message }}
                  b-dropdown.mt-4.ml-2(toggle-class='card-drop p-0' variant='black' right='')
                    template(v-slot:button-content='')
                      i.mdi.mdi-dots-vertical
                    b-dropdown-item 刪除訊息
                    b-dropdown-item 限制留言間隔
                    b-dropdown-item 隱藏用戶
                    b-dropdown-item 封鎖帳號



        .text-item(v-if="!now_room") 選擇一個直播來查看聊天室訊息
</template>
<style lang="sass" scoped>
  
  .scroll-body
    height: 65vh
    overflow: auto
  .group-btn
    display: flex
    justify-content: space-between
    align-items: center
  .group-card
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    cursor: pointer
    
    .img-box
      display: flex
      align-items: center
      background: #fff
      padding: 10px 15px
      border-radius: 5px
      box-shadow: 0 0 3px #ddd
      .text
        flex: 1
        color: #000
      img
        border-radius: 50%
        width: 48px
        max-width: 48px
        min-width: 48px
        height: 48px
  .card-box
    flex: 1
    height: 80vh
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    .text-item
      font-size: 24px
  .room-main
    height: 100%
    width: 100%
    display: flex
    flex-direction: column
    .room-head
      display: flex
      align-items: center
      padding: 1rem
      justify-content: flex-start
      border-bottom: 1px solid #ddd
      .text
        flex: 1
        font-size: 18px
        color: #000
      img
        border-radius: 50%
        width: 48px
        max-width: 48px
        min-width: 48px
        height: 48px
    .room-content
      flex: 1
      overflow: auto
  .media-body
    background: #f1f5f9
    padding: 1rem
</style>
